<template>
  <div class="page-body">
    <div class="table-body">
      <div class="table-header">
        <div class="handle-box">
          <table width="100%">
            <tr>
              <td style="width: 80px">设备Imei：</td>
              <td>
                <el-input
                  v-model="searchItem.imei"
                  size="small"
                  placeholder="请输入设备imei"
                ></el-input>
              </td>
              <td style="width: 80px">合同编号：</td>
              <td>
                <el-input
                  v-model="searchItem.contractNum"
                  size="small"
                  placeholder="请输入合同号"
                ></el-input>
              </td>
              <td style="width: 80px">设备类型：</td>
              <td>
                <el-select
                  style="width: 100%"
                  size="small"
                  v-model="searchItem.deviceType"
                  placeholder="请选择设备类型"
                >
                  <el-option key="0" label="全部" :value="0"></el-option>
                  <el-option key="1" label="塔机" :value="1"></el-option>
                  <el-option key="2" label="升降机" :value="2"></el-option>
                  <el-option key="3" label="单盲吊" :value="3"></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td style="width: 80px; padding-top: 10px">公司名称：</td>
              <td style="padding-top: 10px">
                <el-input
                  v-model="searchItem.companyName"
                  size="small"
                  placeholder="请输入公司名称"
                ></el-input>
              </td>
              <td style="width: 80px; padding-top: 10px">项目名称：</td>
              <td style="padding-top: 10px">
                <el-input
                  v-model="searchItem.projectName"
                  size="small"
                  placeholder="请输入项目名称"
                ></el-input>
              </td>
              <td style="width: 80px; padding-top: 10px">设备状态：</td>
              <td style="padding-top: 10px">
                <el-select
                  style="width: 100%"
                  size="small"
                  v-model="searchItem.deviceState"
                  placeholder="请选择状态"
                >
                  <el-option key="0" label="全部" :value="0"></el-option>
                  <el-option key="1" label="正常" :value="1"></el-option>
                  <el-option key="2" label="停报" :value="2"></el-option>
                  <el-option key="3" label="欠费未停报" :value="3"></el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td style="width: 80px; padding-top: 10px">对口销售：</td>
              <td style="padding-top: 10px">
                <el-select
                  style="width: 100%"
                  size="small"
                  v-model="searchItem.userName"
                  :clearable="true"
                  placeholder="请选择销售"
                >
                  <el-option
                    v-for="item in saleList"
                    :key="item.id"
                    :label="item.userName"
                    :value="item.userName"
                  ></el-option>
                </el-select>
              </td>
              <td></td>
              <td></td>
              <td style="text-align: center; padding-top: 10px" colspan="2">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="small"
                  style="margin-right: 10%"
                  @click="searchBtn"
                  >搜索
                </el-button>
                <el-button
                  type="primary"
                  icon="el-icon-refresh"
                  style="margin-left: 10%"
                  size="small"
                  @click="reset"
                  >重置
                </el-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="table-info">
        <div class="table-button">
          <div>
            <el-table
              :data="tableData"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.1)"
              :max-height="tableHeight"
              style="width: 100%; height: 100%"
              :border="true"
              :row-style="{ height: '40px' }"
              :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
              :cell-style="{ padding: '0', height: lineHeight }"
            >
              <el-table-column
                type="index"
                width="50"
                label="排序"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="imei"
                label="设备Imei"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="contractNum"
                label="合同编号"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="deviceType"
                label="设备类型"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deviceType == 1">塔机</span>
                  <span v-if="scope.row.deviceType == 2">升降机</span>
                  <span v-if="scope.row.deviceType == 3">单盲吊</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="公司名称"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="项目名称"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="sceneNo"
                label="现场编号"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="userName"
                label="对口销售"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="idcard"
                label="服务周期"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.leaseCost <= 0"
                    >{{ scope.row.serviceStartDate }} ~
                    {{ scope.row.serviceEndDate }}</span
                  >
                  <span v-if="scope.row.leaseCost > 0"
                    >{{ scope.row.leaseStartDate }} ~
                    {{ scope.row.leaseEndDate }}</span
                  >
                </template>
              </el-table-column>
              <!-- <el-table-column
                prop="overdueDay"
                label="欠费天数（天）"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.overdueDay < -15">{{
                    scope.row.overdueDay
                  }}</span>
                  <span
                    v-if="
                      scope.row.overdueDay >= -15 && scope.row.overdueDay <= 0
                    "
                    style="color: orange"
                    >{{ scope.row.overdueDay }}</span
                  >
                  <span v-if="scope.row.overdueDay > 0" style="color: red">{{
                    scope.row.overdueDay
                  }}</span>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="deviceState"
                label="状态"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deviceState == 0" style="color: #98fb98"
                    >正常</span
                  >
                  <span v-if="scope.row.deviceState == 1" style="color: red"
                    >停报</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="op"
                label="操作"
                align="center"
                width="180"
              >
                <template slot-scope="scope">
                  <div class="opertion">
                    <i
                      class="iconfont icontingzhi"
                      style="color: #20a0ff"
                      title="终止"
                      @click="endDeivce(scope.row)"
                    ></i>
                    <!-- <i
                      class="iconfont icontingji"
                      title="暂停"
                      style="color: red; margin-left: 20px"
                      @click="stopDevice(scope.row)"
                      v-if="scope.row.deviceState == 0"
                    ></i>
                    <i
                      class="iconfont icontingji"
                      title="启用"
                      style="color: #98fb98; margin-left: 20px"
                      @click="stopDevice(scope.row)"
                      v-if="scope.row.deviceState == 1"
                    ></i> -->
                    <i
                      class="iconfont iconjifeishezhi"
                      title="计费设置"
                      style="color: #20a0ff; margin-left: 20px"
                      @click="editMaterial(scope.row)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="table-data">
          <Pagination
            :pageSize="option.pageSize"
            :pageTotal="totalPage"
            :pageCurrent.sync="option.pageIndex"
            :pageList="pageList"
            @handlePageChange="pageChange"
            @handleSizeChange="pageSizeChange"
          />
        </div>
      </div>
      <el-dialog :title="dialogName" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
        <div style="height: 560px; withd: 100%; margin-left: 0">
          <div class="deviceCollection">
            <div>
              <p>客户类型</p>
               <el-select
                  v-model="deviceDetail.customerType"
                  placeholder="请选择设备类型"
                >
                  <el-option key="1" label="中交路桥集团" :value="1"></el-option>
                  <el-option key="2" label="华西集团" :value="2"></el-option>
                  <el-option key="888" label="其他客户" :value="888"></el-option>
                </el-select>
            </div>
            <div>
              <p>租赁费</p>
              <el-input v-model="deviceDetail.leaseCost" size="small">
                <template slot="append">元/月</template>
              </el-input>
            </div>
            <div>
              <p>开始日期</p>
              <el-date-picker
                size="small"
                v-model="deviceDetail.leaseStartDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div>
              <p>结束日期</p>
              <el-date-picker
                size="small"
                v-model="deviceDetail.leaseEndDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div>
              <p>服务费</p>
              <el-input v-model="deviceDetail.serviceCost" size="small">
                <template slot="append">元/月</template>
              </el-input>
            </div>
            <div>
              <p>开始日期</p>
              <el-date-picker
                size="small"
                v-model="deviceDetail.serviceStartDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div>
              <p>结束日期</p>
              <el-date-picker
                size="small"
                v-model="deviceDetail.serviceEndDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div>
              <p>销售价格</p>
              <el-input v-model="deviceDetail.payCost" size="small">
                <template slot="append">元</template>
              </el-input>
            </div>
            <div>
              <p>免费月数</p>
              <el-input v-model="deviceDetail.freeUseMonth" size="small">
                <template slot="append">月</template>
              </el-input>
            </div>
            <div>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请备注说明"
                v-model="deviceDetail.remarks"
              ></el-input>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveDevice">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog :visible.sync="dialogEndVisible" width="30%" :close-on-click-modal="false">
        <div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请备注说明"
            v-model="deviceEnd.remarks"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogEndVisible = false">取 消</el-button>
          <el-button type="primary" @click="cmdEndDevice">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="dialogStopVisible" width="30%" :close-on-click-modal="false">
        <div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请备注说明"
            v-model="deviceStop.remarks"
          ></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogStopVisible = false">取 消</el-button>
          <el-button type="primary" @click="cmdStopDevice">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageList: [14, 20, 30, 50],
      tableHeight: "",
      tableData: [],
      option: {
        pageSize: 14,
        pageIndex: 1,
      },
      totalPage: 10,
      lineHeight: "",
      searchItem: {
        imei: "",
        contractNum: "",
        companyName: "",
        projectName: "",
        userName: "",
        deviceType: 0,
        deviceState: 0,
        pageIndex: 1,
        pageSize: 14,
      },
      depotType: 0,
      dialogVisible: false,
      dialogName: "",
      deviceDetail: {
        id: "",
        leaseStartDate: "",
        leaseEndDate: "",
        serviceStartDate: "",
        serviceEndDate: "",
        payCost: 0,
        leaseCost: 0,
        serviceCost: 0,
        freeUseMonth: 0,
        remarks: "",
      },
      deviceEnd: {
        id: "",
        remarks: "",
      },
      deviceStop: {
        id: "",
        cmd: 1,
        remarks: "",
      },
      saleList: [],
      dialogEndVisible: false,
      dialogStopVisible: false,
      loading: false,
    };
  },
  methods: {
    endDeivce(val) {
      this.deviceEnd.id = val.id;
      this.dialogEndVisible = true;
    },
    cmdEndDevice() {
      if (this.deviceEnd.remarks == "") {
        this.$message.error("备注必须填写");
        return;
      }
      this.$api.endChargingDevice(this.deviceEnd).then((res) => {
        this.deviceEnd.remarks = "";
        if (res.code == 200) {
          this.searchBtn();
          this.$message.success(res.message);
          this.dialogEndVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    stopDevice(val) {
      this.deviceStop.id = val.id;
      if (val.deviceState == 0) {
        this.deviceStop.cmd = 1;
      } else {
        this.deviceStop.cmd = 0;
      }
      this.dialogStopVisible = true;
    },
    cmdStopDevice() {
      if (this.deviceStop.remarks == "") {
        this.$message.error("备注必须填写");
        return;
      }
      this.$api.updateChargingDeviceStatus(this.deviceStop).then((res) => {
        this.deviceStop.remarks = "";
        if (res.code == 200) {
          this.searchBtn();
          this.$message.success(res.message);
          this.dialogStopVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    saveDevice() {
      this.dialogVisible = false;

      this.deviceDetail.payCost = this.deviceDetail.payCost * 100;
      this.deviceDetail.leaseCost = this.deviceDetail.leaseCost * 100;
      this.deviceDetail.serviceCost = this.deviceDetail.serviceCost * 100;

      this.$api.updateChargingDevice(this.deviceDetail).then((res) => {
        if (res.code == 200) {
          this.searchBtn();
          this.$message.success(res.message);
          this.dialogStopVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    editMaterial(val) {
      this.dialogVisible = true;
      this.dialogName = "计费信息";
      this.deviceDetail.id = val.id;
      this.deviceDetail.customerType = val.customerType;
      this.deviceDetail.leaseStartDate = val.leaseStartDate;
      this.deviceDetail.leaseEndDate = val.leaseEndDate;
      this.deviceDetail.serviceStartDate = val.serviceStartDate;
      this.deviceDetail.serviceEndDate = val.serviceEndDate;
      this.deviceDetail.payCost = (val.payCost / 100).toFixed(2);
      this.deviceDetail.leaseCost = (val.leaseCost / 100).toFixed(2);
      this.deviceDetail.serviceCost = (val.serviceCost / 100).toFixed(2);
      this.deviceDetail.freeUseMonth = val.freeUseMonth;
      this.deviceDetail.remarks = val.remarks;
    },
    pageChange(option) {
      this.option.pageIndex = option;
      this.searchBtn();
    },
    pageSizeChange(val) {
      this.option.pageIndex = 1;
      this.option.pageSize = val;
      this.searchBtn();
    },
    searchBtn() {
      this.searchItem.pageIndex = this.option.pageIndex;
      this.searchItem.pageSize = this.option.pageSize;
      this.loading = true;
      this.$api.getChargingDevices(this.searchItem).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.content;
          this.totalPage = res.data.totalElements;
          this.loading = false;
        }
      });
    },
    reset() {
      this.searchItem.imei = "";
      this.searchItem.contractNum = "";
      this.searchItem.companyName = "";
      this.searchItem.projectName = "";
      this.searchItem.userName = "";
      this.searchItem.deviceType = 0;
      this.searchItem.deviceState = 0;
    },
    getSaleList() {
      this.$api.getSaleUsers().then((res) => {
        if (res.code == 200) {
          this.saleList = res.data;
        }
      });
    },
  },
  created() {
    this.tableHeight = window.innerHeight * 0.66;
    this.searchBtn();
    this.getSaleList();
  },
};
</script>
<style >
.el-input-group__append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 30px;
  line-height: 30px;
  width: 25px;
}
</style>

<style lang="scss" scoped>
.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 3px;
  display: block;
  cursor: pointer;
}

.opertion {
  display: flex;
  justify-content: center;
}

.iconbianji {
  margin-right: 10px;
  color: #20a0ff;
}

.iconshanchu {
  margin-left: 10px;
  color: red;
}

.page-body {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-header {
  height: 140px;
  margin-top: 0.5%;
  background-color: #ffffff;
}

.handle-box {
  padding: 5px;
}

.table-info {
  height: 94%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.table-button {
  flex: 1;
  margin: 0 1%;
}

.table-data {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

td {
  text-align: right;
}

.opertion {
  display: flex;
  justify-content: center;
}

.dialogdiv {
  div {
    text-align: center;
    margin-top: 10px;
  }

  p {
    width: 80px;
    text-align: left;
    display: inline-block;
  }
}

.deviceCollection {
  width: 100%;
  padding: 0 5.7%;
  box-sizing: border-box;
  margin-top: -5%;

  div {
    // width: 25%;
    // float: left;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .el-input {
    width: 80%;
    margin-top: 0;
  }
  .el-select {
    width: 80%;
    margin-top: 0;
  }

  .deviceInput {
    margin: 0;
    padding: 0;
  }

  p {
    //margin-top: 20px;
    width: 80px;
  }
}
</style>